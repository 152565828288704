.optionListContainer{
    width: 200px;
  }
  
  .multiSelectContainer li{
    padding: 5px !important;
    border-bottom: 1px solid #ccc;
    font-size: 0.9em;
  }
.searchBox{
    height: 15px;
    font-size: 0.98rem;
}