.event-item{

}

.event-item .event-item-detail{
  font-size: .9em;
}

.event-item .event-item-detail .event-date{
}

.ph-item{
  /*background: transparent !important;*/
  margin: 0 !important;
  padding: 0 !important;
}

.ph-item > *{
  margin: 0 !important;
}

.ph-item .ph-picture{
  margin: 0;
  /*height: auto !important;*/
  height: 300px!important;
}

