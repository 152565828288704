/*!
 * Table of Contents
 * 00 - Globals
 * 01 - Login
 * 02 - Dashboard
 * 03 - Users
 * 04 - Logs
 * 05 - Doors
 * 06 - Others
 */

/*Yellow: #FFC600*/
/*Blue: #0072CE*/
/*Red: #da291c*/


body{
  background-color: #f0f0f0;
}

body, html, #root{
  height: 100%;
}

/*!  00 - Globals  !*/
.red{ border: 1px solid red; }
.blue{ border: 1px solid blue; }
.green{ border: 1px solid green; }

/*.bg-dark{*/
  /*background-color: #000 !important;*/
/*}*/

.page-title {
  /*margin-top: 20px;*/
  /*margin-bottom: 0;*/
  /*font-size: 24px;*/
}

.page-subtitle {
  font-size: 14px;
  color: #999;
}

.title-name{
  background-color: #343a40;
  color: white;
  /*font-size: 26px;*/
  font-weight: 900;
  text-align: center;
}

table>thead>tr>th {
  /*background-color: #343a40;*/
  /*color: white;*/
  /*font-size: 15px;*/
  /*font-weight: bold;*/
  /*text-align: center;*/
}

table>tbody>tr>td {
  /*font-size: 13px;*/
  /*font-weight: 400;*/
  /*text-align: center;*/
}

.card .card-header{
  /*text-align: center;*/
}

/*.card table.table tr td:first-child,*/
/*.card table.table tr th:first-child{*/
  /*border-width: 1px 1px 2px 0;*/
/*}*/

/*.card table.table tr td:last-child,*/
/*.card table.table tr th:last-child{*/
  /*border-width: 1px 0 2px 1px;*/
/*}*/

/*.card table.table tbody tr td:first-child,*/
/*.card table.table tbody tr th:first-child{*/
  /*border-width: 1px 1px 1px 0;*/
/*}*/

/*.card table.table tbody tr td:last-child,*/
/*.card table.table tbody tr th:last-child{*/
  /*border-width: 1px 0 1px 1px;*/
/*}*/

/*.card table.table{*/
  /*border-width: 0;*/
/*}*/

/*!  01 - Login  !*/
.background-login {
  /*background-image: radial-gradient(*/
    /*circle farthest-corner at center,*/
    /*#c6c6c6 0%,*/
    /*#1C262B 100%*/
  /*);*/

  /*position: fixed;*/
  /*background: #fff;*/
  background: #fcfcfc;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.auth-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: left;
}

.auth-inner {
  /*width: 300px;*/
  margin: auto;
  background: #FFFFFF;
  /*box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);*/
  /*padding: 40px 55px 45px 55px;*/
  /*padding: 3em 2em;*/
  /*border-radius: 15px;*/
  /*transition: all 0.3s;*/
}

.btn-login {
  border: 2px solid #ff1065;
  background-color: #ff1065;
  /*border-radius: 0px;*/
  cursor: pointer;
  /*font-size: 13px;*/
  /*font-weight: bold;*/
  /*width: 60%;*/
}

.btn-login:hover {
  border: 2px solid #ff1065;
  /*border-radius: 0px;*/
  cursor: pointer;
  background-color: #ffffff;
  color: #ff1065;
}


/*Yellow: #FFC600*/
/*Blue: #0072CE*/
/*Red: #da291c*/

.btn-black{
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.btn-black:hover{
  color: #fff;
  background-color: #000;
  /*border: 2px solid #000;*/
}

.message{
  font-size: 13px;
  font-weight: 500;
  color: red;
  margin-top: 15px;
}

/*!  02 - Dashboard  !*/
.card-title {
  /*background-color: #111 !important;*/
  /*color: #fff;*/
  /*[>font-size: 20px;<]*/
  /*font-weight: 900;*/
  /*text-align: left !important;*/
}

/*.card-header-title-by-vault {*/
  /*[>background-color: #343a40;<]*/
  /*background-color: #000;*/
  /*color: white;*/
  /*font-size: 12px;*/
  /*font-weight: 900;*/
  /*text-align: center;*/
  /*padding: 5px;*/
/*}*/

/*.card-hearder{*/
  /*background-color: #343a40;*/
  /*color: white;*/
  /*font-size: 16px;*/
  /*font-weight: 900;*/
  /*text-align: center;*/
/*}*/

.table-tr-th{
  /*background-color: #343a40;*/
  /*color: white;*/
  /*font-size: 10px;*/
  /*font-weight: 900;*/
  /*text-align: center;*/
}

.table-tr-td{
  /*font-size: 10px;*/
  /* text-align: center; */
}

.tooltip-vault-box{
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background-color: #cbcbcb;
  margin-top: 0px;
}


/*!  03 - Users  !*/
.btn-create-user {
  border: 2px solid #ff1065;
  background-color: #ff1065;
  /*border-radius: 3px !important;*/
  cursor: pointer !important;
  color: #ffffff;
  /*font-size: 13px;*/
  font-weight: bold;
  /*width: 80%;*/
}

.btn-create-user:hover {
  border: 2px solid #ff1065;
  border-radius: 3px !important;
  cursor: pointer !important;
  background-color: #ffffff;
  color: #ff1065;
}

.btn-edit {
  border: 2px solid #ff1065;
  border-radius: 20px !important;
  cursor: pointer !important;
  background-color: #ffffff;
  color: #ff1065;
}

.btn-edit:hover {
  border: 2px solid #ff1065;
  background-color: #ff1065;
  /*border-radius: 3px !important;*/
  cursor: pointer !important;
  color: #ffffff;
  /*font-size: 13px;*/
  font-weight: bold;
  /*width: 80%;*/
}

.btn-delete {
  border: 2px solid #640526;
  border-radius: 20px !important;
  cursor: pointer !important;
  background-color: #ffffff;
  color: #640526;
}

.btn-delete:hover {
  border: 2px solid #640526;
  background-color: #640526;
  /*border-radius: 3px !important;*/
  cursor: pointer !important;
  color: #ffffff;
  /*font-size: 13px;*/
  font-weight: bold;
  /*width: 80%;*/
}

/*!  04 - Logs  !*/
.custom-row{
  padding: 0 14px;
}

/*.card-columns-card{*/
  /*text-align: center;*/
/*}*/

/*.card-columns-card:hover{*/
  /*background-color: #414951 !important;*/
/*}*/

.alert-withou-event-logs,
.not-found-table-items{
  padding: 20px;
  font-size: 1.5em;
  color: #ff1065;
  text-align: center;
}


.btn-download-reports {
  border: 2px solid #ff1065;
  background-color: #ff1065;
  border-radius: 3px !important;
  cursor: pointer !important;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
}

.btn-download-reports:hover {
  border: 2px solid #ff1065;
  border-radius: 3px !important;
  cursor: pointer !important;
  background-color: #ffffff;
  color: #ff1065;
}

/*!  05 - Doors  !*/
.picture-container{
  width: calc(25% - 20px);
  height: 200px;
  text-align:center;
  display:block;
  margin: 10px 10px 0 10px;
  float:left;
}

.picture {
  background-color: #eee;
  width: 100%;
  height: 180px;
  cursor: pointer;
  border: 1px solid #999;
  border-radius: 5px;
  padding: 4px;
}

.picture:hover {
  opacity: 0.8;
}

.picture-text{
  margin: 0;
  font-size: 12px;
}

/*!  06 - Others  !*/
.user-status-check input,
.user-status-check label{
  cursor: pointer;
}

.card .card-body .spinner-wrapper{
  margin: 0 auto;
  text-align: center;
  padding-bottom: 3em;
  padding-top: 4em;
  opacity: .8;
}

.spinner-wrapper.full{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  opacity: .8;
}

.spinner-wrapper.inline{
  display: inline-block;
  opacity: .8;
}

.time-control{
  width: 60px !important;
}

.hidden{
  display: none;
}

.empty-list-message{
  padding: 1em;
  font-weight: bold;
  color: #ff1065;
  text-align: center;
}

.sidebar{
  background-color: #000;
  border-right: 1px solid #000;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow: auto;
  width: 15%;
  /*transition: all 0.5s ease 0s;*/

  /*padding: 48px 0 0;*/
  /*border-right: 1px #ddd solid;*/

  /*position: sticky;*/
  /*height: 100vh;*/
  /*top: 0;*/
  /*padding: 0 15px;*/
}

.sidebar-sticky{
  position: sticky;
  top: 0;
  height: 100vh;
  /*padding-top: .5rem;*/
  overflow-x: hidden;
  overflow-y: auto;
}


.sidebar .company-logo{
  /*height: 110px;*/
  /*text-align: center;*/
  /*padding-top: 15px;*/
  /*border-bottom: 1px solid #ddd;*/
}

.breadcrumb,
.breadcrumb .breadcrumb-item{
  background-color: transparent !important;
}



.react-datepicker {
  /*font-size: 1.1em !important;*/
  /*box-shadow: 0 .125em .25em rgba(0,0,0,.08)!important;*/
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.react-datepicker__triangle {
    left: -10px !important;
}

.react-datepicker-popper{
  z-index: 100;
}

.react-datepicker__input-container{
  display: inline-flex;
}

/*..react-datepicker__triangle::before,*/
/*..react-datepicker__triangle::after {*/
  /*@apply border-b-white dark:border-b-slate-900;*/
/*}*/


/*.react-datepicker__header { padding-top: 0.8em !important}*/
/*.react-datepicker__month { margin: 0.4em 1em !important}*/
/*.react-datepicker__day-name, .react-datepicker__day {*/
  /*width: 1.9em !important; */
  /*line-height: 1.9em !important;*/
  /*margin: 0.166em !important;*/
/*}*/
/*.react-datepicker__current-month { font-size: 1em !important }*/
/*.react-datepicker__navigation {*/
  /*top: 1em !important;*/
  /*line-height: 1.7em !important;*/
  /*border: 0.45em solid transparent !important;*/
/*}*/
/*.react-datepicker__navigation--previous { border-right-color: #ccc !important; left: 1em !important }*/
/*.react-datepicker__navigation--next { */
  /*border-left-color: #ccc !important; */
  /*right: 1em !important; */
  /*left: 220px !important */
/*}*/
/*.react-datepicker__time-container { width: 6em !important}*/
/*.react-datepicker-time__header { font-size: 1em !important }*/
/*.react-datepicker-time__header--time { padding-left: 0px !important; padding-right: 0px !important }*/
/*.react-datepicker__time-box { */
  /*margin-left: 0px !important; */
  /*margin-right: 0px !important; */
  /*width:100% !important*/
/*}*/
/*.react-datepicker__time-list { padding: 0 !important; }*/
