.option-selector.dropdown .dropdown-menu{
  padding: 0;
}

.option-selector.dropdown .dropdown-menu .dropdown-item{
  font-size: .9em;
  padding: .4em 1em;
  border-bottom: 1px solid #ddd;
}

.option-selector.dropdown .dropdown-menu .dropdown-item .icon{
  display: inline-block;
  margin-right: .4em;
  width: 1em;
}

.option-selector.dropdown .dropdown-menu .dropdown-item .description{
  display: block;
  font-size: .9em;
  margin-left: 1.5em;
  width: 200px;
  white-space: normal;
}

.option-selector.dropdown .dropdown-menu .dropdown-item:last-child{
  border-bottom: 0 !important;
}

.option-selector.dropdown .dropdown-menu .dropdown-item:hover{
  background-color: #f0f0f0;
  cursor: pointer;
}

.option-selector.dropdown .dropdown-menu .dropdown-item:active{
  background-color: #007bff;
  color: #fff;
}

