@import '~react-widgets/styles.css';
@import "~placeholder-loading/dist/css/placeholder-loading.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sidebar-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.btn{
  cursor: pointer;
}

.btn.btn-danger,
.btn.btn-info{
  color: #fff;
}

.btn .spinner-border{
  width: 1.2rem;
  height: 1.2rem;
  margin-left: .5rem;
  display: none;
}

.btn.loading .spinner-border{
  display: inline-block;
}
