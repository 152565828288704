.forced-indicator{
  background: green;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 8px;
  width: 8px;
}

.forced-indicator.forced{
  background: red;
}

